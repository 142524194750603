<template>

<app-content :loading="is.loading">

	<app-content-head title="Play to Win" :tabs="tabs" />

	<app-content-body :is-grid="4" :loading="is.loading">

		<app-widget-summary :headless="true" :span="3" :text="data.description" />

		<app-widget-info :items="info" />

		<app-widget-chat id="wins" :span="2" />

		<com-leaders :data="data.leaders" />

		<com-winners :data="data.latest" />

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

import comLeaders from './conventioncheckoutwins/Leaders.vue'
import comWinners from './conventioncheckoutwins/Winners.vue'

export default {

	components: {
		comLeaders,
		comWinners
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			data: {}
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Checkout.Wins', text: 'Overview' },
				{ name: 'Convention.Checkout.Wins.Games', text: 'Games' },
				{ name: 'Convention.Checkout.Wins.Winners', text: 'Winners' }
			]

		},

		info: function() {

			return [
				{
					icon: 'play',
					label: 'Games to win',
					text: this.data.games
				},
				{
					icon: 'checkout',
					label: 'Checkouts',
					text: this.data.plays
				},
				{
					icon: 'participants',
					label: 'Participants',
					text: this.data.participants
				},
				{
					icon: 'winner',
					label: 'Winners picked',
					text: this.data.winners
				}
			]

		}

	},

	created: function() {

		this.load()

	},

	methods: {

		load: function() {

			this.$api.get('convention/checkout/wins').then(function(json) {

				Vue.set(this, 'data', json)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>